.main {
  padding: 1rem; 
}

.fields {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.imageField {
  width: 150px;
  max-width: calc(100% - 20px);
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textField {
  flex-grow: 1;
  width: 600px;
  max-width: calc(100% - 20px);
}

.textarea {
  border-radius: 0.5rem; 
  border-width: 1px; 
  width: 100%; 
}

.button {
  width: 100%;
}

.image {
  transition: all 0.05s;
}

.image[data-content="placeholder"]:hover {
  border: 6px dashed var(--color-gray);
  opacity: 0.5;
  object-fit: contain;
}

@media (max-width: 768px) {
  .fields {
    flex-direction: column;
  }

  .imageField {
    width: calc(100% - 20px);
    max-width: 300px;
  }

  .textField {
    width: 100%;
  }
}